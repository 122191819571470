@charset "utf-8";

.clearfix {
  &:after {
    display: table;
    content: '';
    width: 100%;
    height: 0;
    overflow: hidden;
    clear: both;
  }
}

html {
  height: 100%;
}

body {
  min-width: 1000px;
  position: relative;
  height: 100%;
  background: #fff;
  font-size: 14px;
  color: #18242a;
}

header {
  position: absolute;
  color: #fff;
  padding: 30px 90px;
  box-sizing: border-box;
  width: 100%;
  //background-color: rgba(0,0,0,0.3);
  z-index: 2;
  @extend .clearfix;
  &.bg-green {
    background-color: #61bfba;
  }
  h1.logo {
    float: left;
    font-size: 22px;
    img {
      width: 37px;
      height: 37px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  nav {
    float: right;
    font-size: 16px;
    margin-top: 8px;
    a {
      margin-right: 40px;
      color: #fff;
      padding: 0 5px 5px 5px;
      border-bottom: 2px solid transparent;
      &:hover, &.on {
        border-bottom: 2px solid #fff;
      }
    }
  }
}

footer {
  width: 900px;
  margin: 0 auto;
  padding-top: 80px;

  .inner {
    @extend .clearfix;
    nav {
      float: left;
      width: 390px;
      a {
        float: left;
        width: 124px;
        height: 38px;
        line-height: 38px;
        color: #5f6b72;
        &:hover,
        &.on {
          color: #16242a;

        }
      }
    }
    img.code-img {
      float: right;
      width: 112px;
      height: 112px;
    }
  }

  .dividing-line {
    margin-top: 70px;
    background-color: #e5e5e5;
    height: 1px;
  }
  .copy-info {
    color: #b0b0b1;
    line-height: 56px;
    font-size: 12px;
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both
}

p.title {
  font-weight: bold;
  letter-spacing: 0.2em;
}

.border-btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #00cccc;
  position: relative;
  line-height: 38px;
  font-size: 16px;
  color: #00cccc;
  text-align: left;
  padding-left: 26px;
  box-sizing: border-box;
  &:after {
    content: '>';
    display: inline-block;
    vertical-align: middle;
    position: absolute;

    left: 106px;

  }
}

.section-img-cover {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    background-color: #fff;
    object-fit: cover;
    vertical-align: middle;
  }
}

.index-banner {
  height: 100vh;
  max-height: 668px;
  .unslider-wrap {
    color: #fff;
    height: 100%;

    li {
      height: 100%;
      &:nth-of-type(1) {
        background: url(../images/index/banner-index1.jpg) no-repeat 0 0;
        background-size: cover;
        padding-top: 236px;
        text-align: center;
        font-size: 22px;
        img {
          width: 790px;
          height: 85px;
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 20px;
          line-height: 1;
        }
      }
      &:nth-of-type(2) {
        background: #3ec1ab;
        padding-top: 180px;
        .inner {
          width: 900px;
          height: 250px;
          margin: 0 auto;
          position: relative;
        }
        p.txt1 {
          font-weight: bold;
          letter-spacing: 0.2rem;
          font-size: 48px;
          color: #fff;
          vertical-align: middle;
          margin-bottom: 18px;
          img {
            margin-left: 10px;
          }
        }
        p.txt2 {
          font-weight: bold;
          letter-spacing: 0.2rem;
          font-size: 48px;
          color: #16242a;
          margin-bottom: 10px;
        }
        p.txt3 {
          color: #16242a;
          font-size: 20px;
          line-height: 1.6;
          letter-spacing: 0.3rem;
        }
        .img-stream {
          position: absolute;
          top: 0;
          right: 0;
          width: 520px;;
        }
      }
    }
  }
  .news-wrap {
    position: absolute;
    width: 100%;
    bottom: 40px;
    left: 11%;
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    height: 18px;
    line-height: 18px;
    span {
      float: left;
      margin-right: 20px;

    }
    .news-ul {
      float: left;

      li {
        a {
          color: #fff;
          //border-bottom: 1px solid #fff;
          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }
}

.index-section2 {
  padding: 124px 0;
  text-align: center;
  color: #16242a;
  p.title {
    font-size: 60px;
  }
  img {
    width: 282px;
    height: 22px;
    margin: 16px 0 34px;
  }
  p.intro {
    font-size: 16px;
  }
}

.index-section3 {
  background-color: #16242b;
  height: 726px;
  position: relative;
  p.title {
    position: absolute;
    top: 58px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    letter-spacing: normal;
    strong {
      padding: 0 10px;
      font-family: '黑体';
      font-size: 25px;
    }

  }
  .nav-ol {
    position: absolute;
    left: 13%;
    top: 294px;

    li {
      margin-bottom: 78px;
      color: #6f7287;
      font-weight: bold;
      cursor: pointer;
      i {
        width: 38px;
        display: inline-block;
        vertical-align: middle;
      }
      &.on,
      &:hover {
        color: #3aafa9;
        i {
          animation: neon1 1s infinite;
        }
      }
    }
  }
  .content-wrap {
    height: 100%;
    ul {
      height: 100%;
    }
    li {
      height: 100%;
      &:nth-of-type(1) {
        background: url(../images/index/slide1.png) no-repeat center bottom;
        background-size: 1300px 700px;
      }
      &:nth-of-type(2) {
        background: url(../images/index/slide2.png) no-repeat center bottom;
        background-size: 1300px 700px;
      }
      &:nth-of-type(3) {
        background: url(../images/index/slide3.png) no-repeat center bottom;
        background-size: 1300px 700px;
      }
    }
  }
  .btn {
    position: absolute;
    bottom: 85px;
    right: 148px;
    @extend .border-btn;
  }
}

.index-section4 {
  max-width: 1080px;
  margin: 0 auto;
  height: 742px;
  position: relative;
  img.iphone {
    position: absolute;
    left: 50px;
    bottom: 0;
    width: 398px;
    height: 543px;
  }
  img.arrow {
    position: absolute;
    top: 30px;
    left: 240px;
    width: 598px;
    height: 144px;
  }
  .txt-wrap {
    float: right;
    margin-top: 266px;

    p.title {
      font-size: 30px;
      margin-bottom: 26px;
      letter-spacing: 0;
    }
    p.intro {
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 60px;
    }

  }

}

.app-line {
  .code-box {
    display: inline-block;
    position: relative;
    cursor: pointer;
    .code-border {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-44px, -110%);
      display: none;
    }
    &:hover {
      .code-border {
        display: block;
      }
    }

  }
  .iphone-icon, .android-icon {
    width: 140px;
  }
}

.index-section5 {
  background-color: #3aafa9;
  height: 384px;
  text-align: center;
  color: #fff;
  p.title {
    font-size: 60px;
    padding-top: 94px;
    margin-bottom: 10px;
  }
  p.intro {
    padding-top: 30px;
    font-size: 16px;
    line-height: 35px;

  }

}

.index-section6 {
  position: relative;
  height: 776px;
  background: url(../images/index/index-section6-bg.jpg) no-repeat 0 0;
  background-size: 100% 100%;
  .btn {
    position: absolute;
    bottom: 155px;
    right: 148px;
    @extend .border-btn;
  }

}

.index-section7 {
  height: 1120px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: #fff url(../images/index/index-section7-bg.jpg) no-repeat 0 bottom;
  background-size: 100% 768px;
  .plate {
    position: absolute;
    top: 270px;
    right: -160px;
  }
  p.title {
    font-size: 60px;
    padding-top: 70px;
    margin-bottom: 14px;
  }
  p.intro {
    padding-top: 40px;
    font-size: 16px;
    line-height: 35px;

  }
  .txt-wrap {
    float: right;
    text-align: left;
    margin-top: 428px;
    margin-right: 80px;
    color: #fff;
    p.title {
      text-align: left;
      font-size: 30px;
      margin-bottom: 20px;
    }
    p.intro {
      padding-top: 0;
      text-align: left;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 40px;
    }
    .btn {
      @extend .border-btn;
      border: 1px solid #fff;
      color: #fff;
    }
  }
}

.index-section8 {
  background: url(../images/index/index-section8-bg.jpg) no-repeat 0 0;
  background-size: 100% 100%;
  height: 776px;
  color: #fff;
  .txt-wrap {
    padding-top: 294px;
    margin-left: 13.8%;
  }
  p.title {
    font-size: 30px;
    margin-bottom: 30px;
    letter-spacing: 0.1em;
  }
  p.intro {
    line-height: 44px;
  }
}

.training-banner,
.training-section2,
.training-section3,
.training-section5,
.training-section6,
.training-section7,
.training-section9 {
  //max-height: 768px;
  //height: 100vh;
  //max-width: 1440px;
  margin: 0 auto;
  @extend .section-img-cover;
}

.training-section4 {
  //max-height: 1068px;
  //height: 139vh;
  //max-width: 1440px;
  margin: 0 auto;
  @extend .section-img-cover;
}

.training-section8 {
  //max-height: 768px;
  //height: 100vh;
  //max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  p.title {
    padding-top: 80px;
    font-size: 40px;

  }
  p.intro {
    padding-top: 30px;
    margin-bottom: 66px;
    font-size: 18px;

  }
  .course-ul {
    margin: 0 93px;
    padding-bottom: 80px;
    @extend .clearfix;
    li {
      float: left;
      width: calc(100% / 7);
      height: 340px;
      overflow: hidden;
      text-align: center;
      img {
        vertical-align: middle;
      }
      .name {
        margin: 16px 0;
        font-size: 14px;
      }
      a {
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
        color: #12a1a2;
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-left: 8px solid #12a1a2;
          vertical-align: bottom;
        }
        &.gray {
          color: #999;
          text-decoration: none;
          cursor: none;
          &:before {
            border-left-color: #999;
          }
        }
      }
      &:nth-of-type(1) {
        img {
          padding-top: 100px;
        }
      }
      &:nth-of-type(3) {
        img {
          padding-top: 100px;
        }
      }
      &:nth-of-type(4) {
        img {
          padding-top: 30px;
        }
      }
      &:nth-of-type(5) {
        img {
          padding-top: 70px;
        }
      }
      &:nth-of-type(7) {
        img {
          padding-top: 60px;
        }
      }
    }
  }
}

.training-section9 {
  background-color: #18252a;
  color: #fff;
  text-align: center;
  position: relative;
  //@extend .clearfix;
  img {
    //float: left;
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .txt-wrap {
    margin-left: 50%;
    width: 50%;
    padding-bottom: 90px;
    p.title {
      padding-top: 90px;
      padding-bottom: 60px;
      font-size: 38px;
      line-height: 52px;
    }
    .btn {
      @extend .border-btn;
      color: #fff;
      border-color: #fff;
    }
  }

}

.training-section10 {
  //max-height: 680px;
  //height: 100vh;
  //max-width: 1440px;
  margin: 0 auto;
  background: url(../images/health-training/training-section10.jpg) no-repeat 50% 50%;
  background-size: cover;
  padding: 100px 0 70px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  @extend .clearfix;
  p.title {
    font-size: 34px;
    color: #16242a;
    margin-bottom: 50px;
  }
  .news-box,
  .activity-box {
    width: 50%;
    float: left;
    margin-bottom: 50px;
  }
  ul {
    li {
      line-height: 60px;
      height: 60px;
      font-size: 14px;
      a {
        color: #393939;
        line-height: 0.9;
        display: inline-block;
        //transition: all 3s;
        position: relative;
        &:after {
          display: inline-block;
          content: '';
          width: 100%;
          height: 2px;
          background-color: #000;
          transform: scaleX(0);
          transform-origin: 0 0;
          transition: all 0.5s;
        }
        &:hover {
          //font-size:15px;
          //text-decoration: underline;
          color: #000;
          &:after {
            transform: scaleX(1);
            //background-color: transparent;
          }
        }
      }
    }
  }
  a.btn {
    @extend .border-btn;
    color: #16242a;
    border-color: #16242a;
  }
}

.face-banner {
  height: 681px;
  padding: 210px 0 110px;
  width: 1031px;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
  color: #16242a;
  .img-portrait {
    width: 269px;
  }
  .txt-wrap {
    margin-left: 70px;
  }
  p.title {
    font-size: 40px;
    margin-top: 100px;

  }
  .img-txt {
    margin-top: 7px;
    margin-bottom: 35px;

  }
  p.intro {
    font-size: 14px;
    line-height: 20px;
  }
}

.face-section2 {
  background-color: #dde5e6;
  text-align: center;
  .nav-article {
    text-align: left;
    padding: 69px 0 38px;
    width: 1031px;
    margin: 0 auto 59px;
    @extend .clearfix;
    border-bottom: 1px solid #16242a;
    li {
      float: left;
      margin-left: 26px;
      a {
        display: block;
        padding: 6px 20px;
        border-radius: 20px;
        color: #16242a;
        background-color: transparent;

      }
      &.on, &:hover {
        a {
          color: #ffffff;;
          background-color: #16242a;
        }
      }
      &:nth-of-type(1) {
        margin-left: 0;
      }
    }
  }
  .article-ul-wrap {
    text-align: left;
    width: 1031px;
    margin: 0 auto;
    @extend .clearfix;
    li {
      float: left;
      width: 322px;
      height: 390px;
      background-color: #fff;
      position: relative;
      border-radius: 10px;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
      margin-left: 32px;
      margin-bottom: 32px;
      &:nth-of-type(3n+1) {
        margin-left: 0;
      }
      img {
        display: block;
        width: 100%;
        height: 230px;
        border-radius: 10px 10px 0 0;
      }
      p.intro {
        line-height: 30px;
        margin: 20px 24px 0;
        color: #18252a;
        font-size: 16px;
      }
      p.sub-txt {
        position: absolute;
        bottom: 24px;
        left: 24px;
        color: #838383;

      }
      a{
        position:relative;
        height: 100%;
        width: 100%;
        display: block;
        &.video {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/face/face-video-mask.png) 0 0;
            width: 82px;
            height: 82px;

          }
        }
      }

    }
  }
  a.btn {
    margin: 62px auto 82px;
    @extend .border-btn;
    color: #16242a;
    border-color: #16242a;
  }

}

.about-banner {
  position: relative;
  max-height: 768px;
  min-height: 100vh;
  background: url(../images/about-us/banner.jpg) no-repeat 0 0;
  background-size: cover;
  .banner-txt-img {
    position: absolute;
    bottom: 180px;
    right: 150px;

  }
  .btn {
    position: absolute;
    bottom: 100px;
    right: 150px;
    @extend .border-btn;
    color: #fff;
    border-color: #fff;
  }

}

.about-section2 {
  height: 768px;
  background: #fff url(../images/about-us/media.jpg) no-repeat right center;
  color: #212733;
  .txt-wrap {
    padding-top: 243px;
    padding-left: 132px;
  }
  p.title {
    font-size: 24px;
    margin-bottom: 24px;
    letter-spacing: 0;
  }
  p.intro {
    line-height: 35px;
    margin-bottom: 57px;
  }
}

.about-section3 {
  height: 100vh;
  max-height: 766px;
  background-color: #16242a;
  overflow: hidden;
  @extend .clearfix;
  .img-container {
    width: 50%;
    float: left;
    @extend .section-img-cover;
    height: 100%;
    img {
    }
  }
  .txt-wrap {
    float: left;
    padding-top: 148px;
    padding-left: 90px;
    color: #fff;
    .title {
      font-size: 40px;
      margin-bottom: 38px;
      font-weight: normal;
      letter-spacing: 0.08rem;
    }
    .intro {
      margin-bottom: 70px;
      line-height: 42px;
      font-size: 16px;
    }
  }
  .btn {
    @extend .border-btn;
    color: #fff;
    border-color: #fff;
  }

}

.about-section4 {
  height: 100vh;
  max-height: 766px;
  background: url(../images/about-us/contact.jpg) no-repeat center center;
  background-size: cover;
  color: #fff;
  .txt-wrap {
    margin-left: 56%;
    padding-top: 160px;
    p.title {
      font-size: 40px;
      margin-bottom: 35px;
      letter-spacing: 0.08rem;
      font-weight: normal;
    }
    p.intro {
      font-size: 16px;
      line-height: 40px;
    }
    .line {
      height: 2px;
      background-color: #fff;
      width: 100px;
      margin: 16px 0;

    }
  }
}

.article-wrap, .stores-info-wrap {
  width: 1078px;
  padding-top: 173px;
  margin: 0px auto;
  @extend .clearfix;
  article {
    float: left;
    width: 640px;
    h1 {
      font-size: 25px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    .label-ul {
      @extend .clearfix;
      li {
        line-height: 30px;
        float: left;
        margin-right: 20px;
        a {
          display: block;
          padding: 0 20px;
          color: #61bfba;
          border-radius: 15px;
          border: 1px solid #61bfba;
        }
      }
    }
    .dividing-line {
      width: 100%;
      height: 2px;
      background-color: #dfdfdf;
      margin: 48px 0;
    }
    .paragraph {
      font-size: 14px;
      line-height: 32px;
      margin-bottom: 26px;

    }
  }
  aside {
    float: right;
    width: 329px;
    box-sizing: border-box;

    .article-info {
      border: 1px solid #dfdfdf;
      padding: 44px 0;
      text-align: center;
      .author {

        img {
          width: 64px;
          height: 64px;
          margin-bottom: 20px;
        }
      }
      .txt1 {
        font-size: 16px;
        margin-bottom: 15px;

      }
      .txt2 {
        font-size: 14px;
        line-height: 23px;
        color: #8e8e8e;
      }
      .dividing-line {
        width: 140px;
        height: 1px;
        background-color: #dfdfdf;
        margin: 28px auto;
      }
    }

    .other-article {
      h3 {
        margin: 44px 0;
        font-size: 16px;
      }
      .article-list {
        li {
          img {
            width: 100%;
          }
          p {
            margin: 20px 0;
            line-height: 30px;
            a {
              color: #18242a;
              transition: all 3s;
              //&:after {
              //  display: inline-block;
              //  content: '';
              //  width: 100%;
              //  height: 2px;
              //  position: relative;
              //  top: -15px;
              //  background-color: #18242a;
              //  transform: scaleX(0);
              //  transform-origin: 0 0;
              //  transition: all 0.5s;
              //}
              &:hover {
                border-bottom: 1px solid #18242a;
                &:after {
                  transform: scaleX(1);
                }
              }
            }
          }
        }

      }

    }
  }
}

.stores-info-wrap {
  ul {
    li {
      position: relative;
      border-bottom: 2px solid #ddd;
      margin-bottom: 48px;
      &:nth-last-of-type(1) {
        border: none;
        margin-bottom: 0;
      }
      .title {
        font-size: 24px;
        margin-bottom: 18px;
        span {
          margin: 0 20px;
        }
      }
      .info-wrap {
        line-height: 31px;
        color: #666666;
        font-size: 14px;
        padding-bottom: 44px;

      }
      img {
        position: absolute;
        top: 40%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

}

.dividing-line2 {
  height: 1px;
  background-color: #cbcbcb;
  margin: 84px 0 0px;

}

@keyframes bounce {
  0%, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.15, .41, .255, 0.4);
    transform: translateZ(0)
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.55, .03, .655, .03);
    transform: translate3d(0, -10px, 0)
  }

  70% {
    animation-timing-function: cubic-bezier(.55, .03, .655, .03);
    transform: translate3d(0, -6px, 0)
  }

  90% {
    transform: translate3d(0, -1px, 0)
  }
}

.tab-content-container {
  position: relative;
  height: 100vh;
  padding-top: 96px;
  box-sizing: border-box;

  .left-wrap {
    position: absolute;
    width: 570px;
    top: 96px;
    left: 0;
    bottom: 0;
    background-color: #18252a;
    text-align: center;
    .tabs {
      @extend .clearfix;
      position: absolute;
      bottom: 38px;
      left: 50%;
      transform: translateX(-50%);
      li {
        width: 52px;
        height: 52px;
        float: left;
        margin-right: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 55px;
        background-color: #557c83;
        cursor: pointer;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        &:hover, &.on {
          background-color: #5ebcb7;
        }

        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;

        }
        &:nth-of-type(1) {
          img {
            position: relative;
            top: -3px
          }
        }
        &:nth-of-type(2) {
          img {
            position: relative;
            left: 2px;
            top: -4px
          }
        }
        &:nth-of-type(3) {
          img {
            position: relative;
            left: 1px;
            top: -5px
          }
        }
      }
    }
    .left-content {
      h3 {
        padding: 46px 0 14px 0;
        font-size: 28px;
        color: #5ebcb7;

      }
      img.txt {
        margin: 0 auto 30px;
        display: block;

      }
      img.img {
        margin: 0 auto;
        display: block;
      }
    }

  }
  .right-wrap {
    margin-left: 570px;
    height: 100%;
    overflow: auto;
    .item {
      box-sizing: border-box;
      padding: 100px 100px 127px 100px;
      h3 {
        color: #18252a;
        font-size: 28px;
        margin-bottom: 48px;
        text-align: center;
        font-weight: bold;
        line-height: 1.5;
      }
      ul {
        text-align: center;
        li {
          overflow: hidden;
          h4 {
            color: #16242a;
            font-size: 18px;
            margin-bottom: 6px;
          }
          h5 {
            color: #16242a;
            font-size: 14px;
          }
          .dividing-line-img {
            margin: 30px auto;
            display: block;
            width: 1px;
          }
          img[src*=dividing-line] {
            margin: 30px auto;
            display: block;
            width: 1px;
          }

          p {
            color: #16242a;
            line-height: 2;
            font-size: 13px;
          }
          p.txt1 {
            font-size: 15px;
            margin-bottom: 20px;
            margin-top: 60px;
            &:nth-of-type(1) {
              margin-top: 0;
            }
          }
          p.txt2 {
            font-size: 13px;
            color: #727272;
            line-height: 1.4;
          }
          img {
            //display: block;
            width: 100%;
            margin: 30px auto;
            &[src~="dividing-line"] {
              margin: 30px auto;
              display: block;
              width: 1px;
            }

          }
          &:nth-last-of-type(1) {
            img {
              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }
            }
          }
        }

      }
    }
  }

}

.join-us-banner {
  padding-top: 96px;
  background-color: #27363b;
  text-align: center;
  line-height: 406px;
  img {
    vertical-align: middle;
  }
}

.join-us-section1 {
  width: 1130px;
  margin: 0 auto;
  ul {
    li {
      margin-top: 60px;
      padding-bottom: 60px;
      border-bottom: 2px solid #dfdfdf;
      .title-box {
        position: relative;
        height: 100px;
        padding-left: 34px;
        h4 {
          color: #27363b;
          font-size: 16px;
          line-height: 50px;

        }
        p {
          font-size: 12px;
          color: #557c83;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          height: 100px;
          width: 100px;
          box-sizing: border-box;
          z-index: -1;
          background: transparent;
          border-left: 12px solid #557c83;
          border-bottom: 12px solid #557c83;
        }
      }
      .txt-name {
        margin: 38px 0 12px;
        color: #61bfba;
        font-size: 14px;
      }
      .txt-detail {
        line-height: 34px;
        color: #27363b;
        font-size: 14px;
      }
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
}

.join-us-section2 {
  line-height: 160px;
  background-color: #27363b;
  color: #fff;
  p {
    width: 1130px;
    margin: 0 auto;
    a {
      color: #fff;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}

.news-activity-container {
  width: 1080px;
  margin: 0 auto;
  padding-top: 156px;
  .tabs {
    @extend .clearfix;
    border-bottom: 2px solid #dfdfdf;
    margin-bottom: 54px;
    box-sizing: border-box;
    li {
      position: relative;
      float: left;
      padding: 0 20px;
      margin-right: 50px;
      cursor: pointer;
      line-height: 46px;
      box-sizing: border-box;
      font-size: 20px;
      color: #27363b;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        z-index: 2;
        background-color: #27363b;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: all 0.5s;
      }
      &.on, &:hover {
        &:after {
          transform: scaleX(1);
        }
      }

    }
  }
  .content {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 44px;

      .img-content {
        background-color: #ccc;
        width: 283px;
        height: 160px;
        text-align: center;
        border: 1px solid #ccc;
        img {
          vertical-align: middle;
          width: 100%;
          height: 100%;
          background-color: #fff;
          object-fit: cover;
        }
      }
      .txt-content {
        width: 750px;
        position: relative;
        height: 160px;
        overflow: hidden;
        h3 {
          color: #27363b;
          font-size: 16px;
          margin-bottom: 14px;
        }
        h4 {
          height: 88px;
          text-overflow: ellipsis;
          line-height: 26px;
          color: #666666;
          font-size: 14px;

        }
        p {
          font-size: 12px;
          position: absolute;
          bottom: 2px;
          left: 0;
          color: #666666;
        }

      }

    }
    a.btn {
      @extend .border-btn;
      color: #27363b;
      border-color: #27363b;
      margin: 93px auto 93px;
      display: block;
    }
  }
}

//@media (min-width: 1440px) {
//  body {
//    max-width: 1440px;
//    margin:0 auto;
//  }
//  header {
//    max-width: 1440px;
//    transform: translateX(-50%);
//    left: 50%;
//    margin: 0 auto;
//
//  }
//}

@media (min-height: 900px) {
  .tab-content-container {
    .left-wrap {
      .left-content {
        img.img {
          margin-top: 30%;
        }
      }
    }

  }

}

